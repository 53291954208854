import Vue from 'vue'
import Router from 'vue-router'
import { createPageProgress } from '@alltuu/progress'
import { aiTrackEvent } from '../module/aiTrack'
import { nowDomain } from "../config/env"
import store from '../store/store'
import action from '../store/action'
import { getCookie } from "../module/cookie";

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}



function lazyLoadView (AsyncView, loading, error ) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: loading,
    error: error,
    delay: 0,
    timeout: 10000
  })

  return Promise.resolve({
    functional: true,
    render (h, { data, children }) {
      return h(AsyncHandler, data, children)
    }
  })
}

const workbenchLazyLoadView = (asyncView) => lazyLoadView(asyncView, require('../components/workbenchSkeleton.vue').default, require('../components/workbenchSkeleton.vue').default)

// 新修图工作台
const workbench = r => require.ensure([], () => r(require('../page/workbench/workbench.vue')), 'workbench')
// 新修图主页
const newRetouch = r => require.ensure([], () => r(require('../page/workbench/childPage/retouch/retouch.vue')), 'newRetouch')
// 新修图页面视频页面
const newRetouchVideo = r => require.ensure([], () => r(require('../page/workbench/childPage/video/videopage.vue')), 'newRetouchVideo')
const retouchingClient = r => require.ensure([], () => r(require('../page/workbench/childPage/retouchingClient/retouchingClient.vue')), 'retouchingClient')
// 新修图页面全景图图片页面
const newRetouch720 = r => require.ensure([], () => r(require('../page/workbench/childPage/720/index.vue')), 'newRetouch720')
// 新排序页面
const newSort = r => require.ensure([], () => r(require('../page/workbench/childPage/imagesort/imagesort.vue')), 'newImageSort')

//socket测试页面
const socket = r => require.ensure([], () => r(require('../page/websocket/websocket.vue')), 'websocket')
// 页面拦截
const middleHref = r => require.ensure([], () => r(require('../page/middleHref/index.vue')), 'middleHref')
//404
const notFound = r => require.ensure([], () => r(require('../page/404/notFound.vue')), 'notFound')
//v跳转
const vToHomePage = r => require.ensure([], () => r(require('../page/alltuuHomePage2020/homePage.vue')), 'vToHomePage')
//我的星标大片
const myStarPhoto = r => require.ensure([], () => r(require('../page/myStarPhoto/myStarPhoto.vue')),'myStarPhoto')
//云存储
// const albumStorage = r => require.ensure([], () => r(require('../page/storage/albumStorage.vue')), 'albumStorage')
//摄影师主页相册
const albuml = r => require.ensure([], () => r(require('../page/homePage/albuml.vue')), 'albuml')
//企业定制主页相册
const enterprise = r => require.ensure([], () => r(require('../page/enterpriseHomepage/enterpriseHomepage.vue')), 'enterpriseHomepage')
//修图页面
// const retouch = r => require.ensure([], () => r(require('../page/retouch/retouch.vue')), 'retouch')
//vip会员协议页面
const vipduty = r => require.ensure([], () => r(require('../page/vipduty/vipduty.vue')), 'vipduty')
//宣传页面
const propagandist = r => require.ensure([], () => r(require('../page/propagandist/newMobileIndex')), 'propagandist')
//M端视频案例
const mVideoCases = r => require.ensure([], () => r(require('../page/mVideoCases/mVideoCases')), 'mVideoCases')
//PC作品页面
const work = r => require.ensure([], () => r(require('../page/homePage/workPage.vue')), 'work')
//PC摄影师详情页面
const detail = r => require.ensure([], () => r(require('../page/homePage/detailPage.vue')), 'detail')
//PC直播案例页面
const cases = r => require.ensure([], () => r(require('../page/cases/cases.vue')), 'cases')
//PC直播案例SEO页面
const seoCases = r => require.ensure([], () => r(require('../page/seoCases/index.vue')), 'seoCases')
//PC下载页面
const download = r => require.ensure([], () => r(require('../page/download/download.vue')), 'download')
// 新打包下载
const packageDownload = r => require.ensure([], () => r(require('../page/packageDownload/download.vue')), 'download')
// 打包管理页
const packageManage = r => require.ensure([], () => r(require('../page/packageDownload/packageManage.vue')), 'download')
//PC密码页面
const password = r => require.ensure([], () => r(require('../page/download/components/password.vue')), 'password')
// 新打包下载密码页面
const packagePassword = r => require.ensure([], () => r(require('../page/packageDownload/components/password.vue')), 'password')
//M端打开PC密码页面
const mobilePassword = r => require.ensure([], () => r(require('../page/download/components/mobilePrompt.vue')), 'mobilePassword')
//新M端打开PC密码页面
const newMobilePassword = r => require.ensure([], () => r(require('../page/packageDownload/components/mobilePrompt.vue')), 'mobilePassword')
//PC审核页面
const auditing = r => require.ensure([], () => r(require('../page/auditing/auditing.vue')), 'auditing')
//PC审核页面  照片审核
const photoAudit = r => require.ensure([], () => r(require('../page/auditing/page_block/photoAudit.vue')), 'photoAudit')
//PC审核页面  视频审核
const videoAudit = r => require.ensure([], () => r(require('../page/auditing/page_block/videoAudit.vue')), 'videoAudit')


//PC AI修图
const aiIntroducePage = r => require.ensure([], () => r(require('../page/aiIntroduce/index.vue')), 'aiIntroduce')
//PC AI旋转
const aiSpinPage = r => require.ensure([], () => r(require('../page/aiSpin/index.vue')), 'aiSpin')


//PC测试页面
const test = r => require.ensure([], () => r(require('../page/testPage/test.vue')), 'test')
const lut = r => require.ensure([], () => r(require('../page/lut/index.vue')), 'lut')
//PC微信登录重定向页面
const wxRedirect = r => require.ensure([], () => r(require('../page/wxRedirect/wxRedirect.vue')), 'wxRedirect')
//PC摄影师开放平台
const photoplatform = r => require.ensure([], () => r(require('../page/photoplatform/photoplatform.vue')), 'photoplatform')
//PC个人中心 闪传服务
const personalCenterFlashTransmission = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/flashTransmission.vue')), 'flashTransmission')
//PC个人中心 闪传服务-购买闪传匣
const personalCenterBuyBox = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/buyBox/buyBox.vue')), 'buyBox')
//pc 闪传商城 --闪传会员
const personalCenterBuyVip = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/buyVip/buyVip.vue')), 'buyVip')
// pc 闪传生成 新闪传会员页
const newPersonalCenterBuyVip = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/newMemberShop/index.vue')), 'buyVip')
//pc 闪传商城 --高级功能
const personalCenterAdvancedFeatures = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/advancedFeatures/advancedFeatures.vue')), 'buyVip')
//pc 闪传商城 --购买存储包
const personalStoragePackage = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/storagePackage/storagePackage.vue')), 'buyPackage')
//PC个人中心 闪传服务-购买技术服务
const personalCenterBuyService = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/buyService/buyService.vue')), 'buyService')
//PC个人中心 闪传服务-购买闪传配件
const personalCenterBuyFittings = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/buyFittings/buyFittings.vue')), 'buyFittings')
//PC个人中心 闪传服务-购买720全景相机
const personalCenterBuy720Camera = r => require.ensure([], () => r(require('../page/personalCenter/childPage/flashTransmission/childPage/buy720Camera/buy720Camera.vue')), 'buy720Camera')
// 个人中心 个人资料
const personalData = r => require.ensure([], () => r(require('../page/personalCenter/childPage/personalData/personalData.vue')), 'personalData')
// 个人资料与安全
const personalSetting = r => require.ensure([], () => r(require('../page/personalCenter/childPage/personalSetting/index.vue')), 'personalSetting')

// 个人中心 安全中心
const securityCenter = r => require.ensure([], () => r(require('../page/personalCenter/childPage/securityCenter/securityCenter.vue')), 'securityCenter')
// 个人中心 我的资产
const myAssets = r => require.ensure([], () => r(require('../page/personalCenter/childPage/myAssets/myAssets.vue')), 'myAssets')
// 实名认证
const realNamePage = r => require.ensure([], () => r(require('../page/personalCenter/childPage/realNamePage/realNamePage.vue')), 'realNamePage')
//PC个人中心 订单列表
const orderHistory = r => require.ensure([], () => r(require('../page/personalCenter/childPage/orderHistory/orderHistory.vue')), 'orderHistory')
//PC 问答审核页面
const QAPage = r => require.ensure([], () => r(require('../page/QAPage/index.vue')), 'QAPage')
//PC 视频案例页面
const videoCases = r => require.ensure([], () => r(require('../page/videoCases/index.vue')), 'videoCases')


// 供应商简历
//摄影类
const photoplatformResume = r => require.ensure([], () => r(require('../page/platformResume/photoplatformResume.vue')), 'photoplatformResume')
// 摄像类
const videoplatformResume = r => require.ensure([], () => r(require('../page/platformResume/videoplatformResume.vue')), 'videoplatformResume')
//短视频
const shortVideoPlatformResume = r => require.ensure([], () => r(require('../page/platformResume/shortVideoPlatformResume.vue')), 'shortVideoPlatformResume')
//修图服务
const retouchPlatformResume = r => require.ensure([], () => r(require('../page/platformResume/retouchPlatformResume.vue')), 'retouchPlatformResume')
//视频直播
const liveVideoPlatformResume = r => require.ensure([], () => r(require('../page/platformResume/liveVideoPlatformResume.vue')), 'liveVideoPlatformResume')

//PC个人中心
const personalCenter = r => require.ensure([], () => r(require('../page/personalCenter/personalCenter.vue')), 'personalCenter')
//PC个人中心 我的钱包
const personalCenterWallet = r => require.ensure([], () => r(require('../page/personalCenter/childPage/wallet/newWallet.vue')), 'wallet')


const about = r => require.ensure([], () => r(require('../page/about/about.vue')), 'about')
const aboutAgree = r => require.ensure([], () => r(require('../page/about/children/agree.vue')), 'agree')
const aboutFeedback = r => require.ensure([], () => r(require('../page/about/children/feedback.vue')), 'feeback')
const aboutNews = r => require.ensure([], () => r(require('../page/about/children/news.vue')), 'news')
const aboutNewsInfo = r => require.ensure([], () => r(require('../page/about/children/newsinfo.vue')), 'newsinfo')
// const aboutNewsInfo = r => require.ensure([], () => r(require('../page/about/children/newsInfo.vue')), 'newsInfo')
const aboutContactUs = r => require.ensure([], () => r(require('../page/about/children/contactUs.vue')), 'contactUs')
const aboutCopyRight = r => require.ensure([], () => r(require('../page/about/children/copyright.vue')), 'copyright')
const aboutUs = r => require.ensure([], () => r(require('../page/aboutUs/aboutUs.vue')), 'aboutUs')

const appDownload = r => require.ensure([], () => r(require('../page/appDownload/appDownload.vue')), 'appDownload')
const retouchDownload = r => require.ensure([], () => r(require('../page/retouchDownload/retouchDownload.vue')), 'retouchDownload')
const yearIntroduction = r => require.ensure([], () => r(require('../page/yearIntroduction/yearIntroduction.vue')), 'yearIntroduction')
const login = r => require.ensure([], () => r(require('../page/login/login.vue')), 'login')
// 手机密码登录页
const passwordLogin = r => require.ensure([], () => r(require('../page/login/compoments/passwordLogin.vue')), 'passwordLogin')
// 验证码登录页
const verificationLogin = r => require.ensure([], () => r(require('../page/login/compoments/verificationLogin.vue')), 'verificationLogin')
//找回密码页
const retrievePassword = r => require.ensure([], () => r(require('../page/login/compoments/retrievePassword.vue')), 'retrievePassword')
// 注册页
const newRegister = r => require.ensure([], () => r(require('../page/login/compoments/newRegister.vue')), 'newRegister')
// 快速注册页
const quickRegister = r => require.ensure([], () => r(require('../page/login/compoments/quickRegister.vue')), 'quickRegister')

const userAgreement = r => require.ensure([], () => r(require('../page/userAgreement/userAgreement.vue')), 'userAgreement')

// 修图页面 - 直播排序 | 图片排序
const imgMove = r => require.ensure([], () => r(require('../page/imgMove/imgMove.vue')), 'imgMove')

// dingding服务商
const dingServer = r => require.ensure([], () => r(require('../page/dingServer/dingServer.vue')), 'dingServer')

//PC摄影师合作页面
const cooperationPage = r => require.ensure([], () => r(require('../page/cooperationPage/cooperationPage.vue')), 'cooperationPage')

// 影像人才
const industryTalent = r => require.ensure([], () => r(require('../page/industryTalent/index.vue')), 'industryTalent')

//评分详情表
const scoreDetail = r => require.ensure([], () => r(require('../page/workbench/childPage/scoreDetail/index.vue')), 'cooperationPage')
// 开放平台
const openPlatform = r => require.ensure([], () => r(require('../page//openPlatform/index.vue')), 'openPlatform')
// 会员权益表页面
const memberBenefits = r => require.ensure([], () => r(require('../page/memberBenefits/index.vue')), 'memberBenefits')
// 站内信iframe嵌入页面
const messageIframe = r => require.ensure([], () => r(require('../page/personalWorkbench/components/messageStation/ImgTextContent.vue')), 'messageIframe')
// piufoto页面
const piufotoLand = r => require.ensure([], () => r(require('../page/piufoto/landPage.vue')), 'piufotoLand')
const piufotoHome = r => require.ensure([], () => r(require('../page/piufoto/index.vue')), 'piufotoHome')


const website = r => require.ensure([], () => r(require('../page/website/index.vue')), 'website')
//requiresAuth 必须登录，否则跳转登录页面
//canNotLogin 进入后免登录

const router = new Router({
  mode: 'history',
  routes: [
    { //主页 路径www.guituu.com 显示website；alltuu.com显示 vToHomePage
      path: '/',
      name: 'homePageIndex',
      meta: {
        canNotLogin: true,
        describe: '首页'
      },
      component: nowDomain === 'piufoto' ? piufotoHome : (nowDomain === 'guituu' ? website : vToHomePage)
    }, { //主页
      path: '/index',
      name: 'vToHomePage',
      meta: {
        canNotLogin: true,
        describe: '首页',
        antDCss: true
      },
      beforeEnter: (to, from, next) => {
        // 如果条件满足则重定向到/
        if (process.env.VUE_APP_ENV === 'locahost') {
          next()
        } else {
          next('/') // 条件不满足，继续正常导航
        }
      },
      component: vToHomePage
    }, {
      // piufoto测试页面
      path: '/landpage',
      name: 'piufotoLand',
      meta: {
        canNotLogin: true,
        describe: 'piufoto的landPage页面'
      },
      component: piufotoLand
    },{ //页面拦截
      path: '/middleHref',
      name: 'middleHref',
      meta: {
        canNotLogin: true
      },
      component: middleHref
    },
    { //微信登录重定向页面
      path: '/wxRedirect',
      name: 'wxRedirect',
      meta: {
        canNotLogin: true
      },
      component: wxRedirect
    },
    { // 新修图工作台
      path: '/workbench',
      name: 'workbench',
      meta: {
        requiresAuth: true,
        antDCss: true
      },
      component: workbench,
      redirect: { name: 'newRetouch' },
      children: [
        {
          path: 'retouch/:id(\\d{10})',
          name: 'newRetouch',
          component: newRetouch,
          meta: {
            antDCss: true,
            describe: '修图网页后台-照片'
          }
        },
        {
          path: 'retouchingClient/:id(\\d{10})',
          name: 'retouchingClient',
          component: retouchingClient,
          meta: {
            antDCss: true,
            describe: '修图网页后台-修图客户端'
          }
        },
        {
          path: 'sort/:id(\\d{10})',
          name: 'newSort',
          component: newSort,
          meta: {
            describe: '修图网页后台-直播排序'
          }
        },
        {
          path: 'video/:id(\\d{10})',
          name: 'newRetouchVideo',
          component: newRetouchVideo,
          meta: {
            describe: '修图网页后台-照片'
          }
        },
        {
          path: 'photo720/:id(\\d{10})',
          name: 'newRetouchPhoto720',
          component: newRetouch720
        }
      ]
    },
    //帮助中心，跳转到外链
    {
      path: '/supportCenter',
      name: 'supportCenter',
      children: [{
        path: '*',
        component: () => {
          location.href = 'http://faq.alltuu.com/'
        }
      }],
      component: () => {
        location.href = 'http://faq.alltuu.com/'
      }
    },
    { //我的星标大片
        path: '/myStarPhoto',
        name: 'myStarPhoto',
        meta: {
            canNotLogin: true,
            describe: '星标大片'
        },
        component: myStarPhoto
    },
    { //socket测试页面
        path: '/socket',
        name: 'socket',
        meta: {
            canNotLogin: true
        },
        component: socket
    },
    { //dingding服务商
        path: '/dingServer',
        name: 'dingServer',
        meta: {
            canNotLogin: true,
            describe: '钉钉服务商'
        },
        component: dingServer
    },
    {
      // 三端融合 非灰度用户进入灰度用户 跳转页面
        path: '/noAuthOfThreeTer',
        name: 'noAuthOfThreeTer',
        meta: {
            canNotLogin: true,
        },
        component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/noAuthOfThreeTer/index.vue')

    },
    { //登录页
        path: '/login',
        name: 'login',
        meta: {
            canNotLogin: true,
            describe: '登录页'
        },
        component: login,
        redirect: '/login/passwordLogin',
        children: [{
          path: '/login/passwordLogin',
          name: 'passwordLogin',
          meta: {
            canNotLogin: true,
            describe: '手机密码登录'
          },
          component: passwordLogin,
        }, {
          path: '/login/verificationLogin',
          name: 'verificationLogin',
          meta: {
            canNotLogin: true,
            describe: '验证码登录'
          },
          component: verificationLogin,
        }, {
          path: '/login/retrievePassword',
          name: 'retrievePassword',
          meta: {
            canNotLogin: true,
            describe: '找回密码'
          },
          component: retrievePassword,
        }, {
          path: '/login/newRegister',
          name: 'newRegister',
          meta: {
            canNotLogin: true,
            describe: '注册账号'
          },
          component: newRegister,
        }, {
          path: '/login/quickRegister',
          name: 'quickRegister',
          meta: {
            canNotLogin: true,
            describe: '快速注册'
          },
          component: quickRegister,
        }]
    },
    {
      name: "crmCloudStorage",
      path: '/crmCloudStorage',
      component: () => import(/* webpackChunkname: "personalWorkbenchCloudStorage" */ '../page/personalCenter/childPage/newCloudStorage/index.vue'),
      meta: {
        canNotLogin: true,
        progress: true,
        antDCss: true,
        describe: 'crm内嵌的存储管理页面'
      }
    },
    { //app下载页面
        path: '/appDownload',
        name: 'appDownload',
        meta: {
            canNotLogin: true,
            describe: 'app下载页面'
        },
        component: appDownload
    },
    { //retouch下载页面
        path: '/retouchDownload',
        name: 'retouchDownload',
        meta: {
            canNotLogin: true,
            describe: '修图客户端下载页面'
        },
        component: retouchDownload
    },
    // { //云存储页面
    //   path: '/albumStorage',
    //   name: 'albumStorage',
    //   meta: {
    //     requiresAuth: true,
    //     describe: '修图客户端下载页面'
    //   },
    //   component: albumStorage
    // },
    { //宣传页面
      path: '/alltuuPretty',
      name: 'propagandist',
      component: propagandist,
      meta: {
        describe: 'm端官网'
      }
    },
    { //M端视频案例页
      path: '/mVideoCases',
      name: 'mVideoCases',
      component: mVideoCases,
      meta: {
        describe: 'M端视频案例页'
      }
    },
    { //摄影师主页
      path: '/albuml',
      name: 'albuml',
      meta: {
        canNotLogin: true,
        describe: '个人主页'
      },
      component: albuml,
    },
    { //摄影师主页
        path: '/enterprise',
        name: 'enterprise',
        meta: {
          canNotLogin: true,
          describe: '个人主页'
        },
        component: enterprise
    },
    { // 直播页面 - 图片排序
      path: '/imgMove/:id',
      name: 'imgMove',
      meta: {
        requiresAuth: true,
        describe: '图片排序'
      },
      component: imgMove
    },
    { //作品页面
      path: '/work',
      name: 'work',
      meta: {
        canNotLogin: true,
        describe: '摄影师作品'
      },
      component: work
    },
    { //资料页面
      path: '/detail',
      name: 'detail',
      meta: {
        canNotLogin: true,
        describe: '摄影师资料'
      },
      component: detail
    },
    { //视频直播问答管理页面
      path: '/QAPage',
      name: 'QAPage',
      meta: {
        requiresAuth: true,
        describe: '视频直播问答管理页面'
      },
      component: QAPage
    },
    { //视频案例页面
      path: '/videoCases',
      name: 'videoCases',
      meta: {
        // requiresAuth: true
        canNotLogin: true,
        antDCss: true,
        describe: '视频案例页面'
      },
      component: videoCases
    },
    { //案例页面
      path: '/cases',
      name: 'cases',
      meta: {
        // requiresAuth: true,
        canNotLogin: true,
        antDCss: true,
        describe: '相册案例页面'
      },
      component: cases
    },
    { // SEO案例页面
      path: '/newCases',
      name: 'newCases',
      meta: {
        canNotLogin: true,
        describe: '相册案例SEO首页'
      },
      redirect: '/newCases/index',
      component: seoCases,
      children: [{
        path: 'index',
        name: 'newCasesIndex',
        meta: {
          canNotLogin: true,
          describe: '相册案例SEO首页'
        },
        component: () => import('../page/seoCases/seoCases.vue')
      },{
        path: 'newCasesArticle/:id',
        name: 'newCasesArticle',
        meta: {
          canNotLogin: true,
          describe: '相册案例SEO详情页面'
        },
        component: () => import('../page/seoCases/seoArticle.vue')
      }]
    },
    { // 专题微站页面
      path: '/specialTopic',
      name: 'specialTopic',
      meta: {
        canNotLogin: true,
        describe: '专题案例页面',
        antDCss: true
      },
      component: () => import(/* webpackChunkname: "specialTopic" */ '../page//specialTopic/index.vue')
    },
    { //下载页面
      path: '/download',
      name: 'download',
      meta: {
        canNotLogin: true,
        describe: '打包下载页面'
      },
      component: download
    },
    { //下载页面
      path: '/packageDownload',
      name: 'packageDownload',
      meta: {
        canNotLogin: true,
        antDCss: true,
        describe: '新打包下载页面'
      },
      component: packageDownload
    },
    { //下载密码页面
      path: '/password',
      name: 'password',
      meta: {
        canNotLogin: true,
        describe: '打包下载-密码页面'
      },
      component: password
    },
    { //下载密码页面
      path: '/packagePassword',
      name: 'packagePassword',
      alias: '/package',
      meta: {
        canNotLogin: true,
        describe: '新打包下载-密码页面'
      },
      component: packagePassword
    },
    { //M端打开下载密码页面
      path: '/mobilePassword',
      name: 'mobilePassword',
      meta: {
        canNotLogin: true,
        describe: '打包下载-m端-密码页面'
      },
      component: mobilePassword
    },
    { //新M端打开下载密码页面
      path: '/newMobilePassword',
      name: 'newMobilePassword',
      alias: '/packageTips',
      meta: {
        canNotLogin: true,
        describe: '打包下载-m端-密码页面'
      },
      component: newMobilePassword
    },
    { //摄影师开放平台
      path: '/photoplatform',
      name: 'photoplatform',
      meta: {
        canNotLogin: true,
        describe: '摄影师开放平台'
      },
      component: photoplatform
    },
    { //PC摄影师开放平台简历
      path: '/photoplatform/photoplatformResume',
      name: 'photoplatformResume',
      meta: {
        canNotLogin: true,
        describe: '摄影师开放平台-平台简历'
      },
      component: photoplatformResume
    },
    {
      path: '/videoPlatform/videoplatformResume',
      name: 'videoplatformResume',
      meta: {
          canNotLogin: true
      },
      component: videoplatformResume
    },
    {
      path: '/shortVideoPlatform/shortVideoPlatformResume',
      name: 'shortVideoPlatformResume',
      meta: {
          canNotLogin: true
      },
      component: shortVideoPlatformResume
    },
    {
      path: '/retouchPlatform/retouchPlatformResume',
      name: 'retouchPlatformResume',
      meta: {
          canNotLogin: true
      },
      component: retouchPlatformResume
    },
    {
      path: '/liveVideoPlatform/liveVideoPlatformResume',
      name: 'liveVideoPlatformResume',
      meta: {
          canNotLogin: true
      },
      component: liveVideoPlatformResume
    },
    {
        path: '/aiIntroduce',
        name: 'aiIntroduce',
        meta: {
          canNotLogin: true,
          describe: 'AI修图体验页',
          antDCss: true
        },
        component: aiIntroducePage
    },
    {
      path: '/aiSpin',
      name: 'aiSpin',
      meta: {
        canNotLogin: true,
        antDCss: true,
        describe: 'AI旋转'
      },
      component: aiSpinPage
    },
    { //测试页面
      path: '/test',
      name: 'test',
      meta: {
        canNotLogin: true
      },
      component: test
    },
    {
      // lut特殊页面
      path: '/lut',
      name: 'lut',
      meta: {
        canNotLogin: true
      },
      component: lut
    },
    { //新版工作台
      path: '/newWorkbench/:id(\\d{10})',
      redirect: '/newWorkbench/:id(\\d{10})/setting/basicSetting',
      component: () => import(/* webpackChunkName: "newWorkbench" */ '../page/newWorkbench'),
      children: [{ // 设置功能
        path: 'setting',
        redirect: '/newWorkbench/:id(\\d{10})/setting/basicSetting',
        component: () => import(/* webpackChunkName: "newWorkbench" */ '../page/newWorkbench/setting'),
        children: [{
          path: 'basicSetting',
          name: "newWorkbench-basicSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-基本设置'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/basicSetting')
        }, {
          path: 'albumCoverSetting',
          name: "newWorkbench-albumCoverSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-相册封面'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/albumCoverSetting')
        }, {
          path: 'layoutSkinSetting',
          name: "newWorkbench-layoutSkinSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-基本信息区'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/layoutSkinSetting')

        }, {
          path: 'shareSetting',
          name: "newWorkbench-shareSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-分享设置'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/shareSetting')
        }, {
          path: 'classSetting',
          name: "newWorkbench-classSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-照片分类'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/classSetting')
        }, {
          path: 'interactiveUploadSetting',
          name: "newWorkbench-interactiveUploadSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-互动上传'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/interactiveUploadSetting')
        }, {
          path: 'interactiveMessageSetting',
          name: "newWorkbench-interactiveMessageSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-互动留言'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/interactiveMessageSetting')
        }, {
          path: 'selfSetting',
          name: "newWorkbench-selfSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-私密性设置'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/selfSetting')
        }, {
          path: 'infoCardSetting',
          name: "newWorkbench-detailSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-信息卡片'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/detailSetting')
        }, {
          path: 'topPublicitySetting',
          name: "newWorkbench-topPublicitySetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-顶部宣传位'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/topPublicitySetting')
        }, {
          path: 'bottomPublicitySetting',
          name: "newWorkbench-bottomPublicitySetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-侧边广告'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/bottomPublicitySetting')
        }, {
          path: 'authorization',
          name: "newWorkbench-authorization",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-第三方授权'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/authorization')
        }, {
          path: 'startUpSetting',
          name: "newWorkbench-startUpSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-启动页海报'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/startUpSetting')
        }, {
          path: 'loadingGraphSetting',
          name: "newWorkbench-loadingGraphSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加载图'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/loadingGraphSetting')
        }, {
          path: 'photoPrintSetting',
          name: "newWorkbench-photoPrintSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-云冲印'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/photoPrintSetting')
        },{
          path: 'liveBroadCastSetting',
          name: "newWorkbench-liveBroadCastSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-图文直播'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/liveBroadCastSetting')
        }, { // 水印设置设置
          path: 'waterMarkSetting',
          name: "newWorkbench-waterMarkSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-批量打水印'
          },

          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/waterMarkSetting')
        },
         { // 付费下载设置
          path: 'imgPayDownload',
          name: "newWorkbench-imgPayDownload",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-付费打赏'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/imgPayDownload')
        },
        { // 九宫格设置
          path: 'sudokuSetting',
          name: "newWorkbench-sudokuSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-九宫格拼图'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/sudokuSetting')
        }, { // 短视频设置
          path: 'videoSetting',
          name: "newWorkbench-videoSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-短视频设置'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/videoSetting')
        }, {
          path: 'topBannerSetting',
          name: "newWorkbench-topBannerSetting",
          meta: {
            requiresAuth: true,
            antDCss: true,
            describe: '相册设置-顶部广告'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/topBannerSetting')
        },{
          path: 'endingSetting',
          name: "newWorkbench-endingSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-Ending广告'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/endingSetting')
        }, { // 摄影师设置
          path: 'photographerSetting',
          name: "newWorkbench-photographerSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加摄影师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/photographerSetting')
        }, { //AI修图师设置
          path: 'aiRetoucherSetting',
          name: "newWorkbench-aiRetoucherSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加AI修图师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/retoucherSetting')
        }, { //AI修图师设置
          path: 'aiComicSetting',
          name: "newWorkbench-aiComicSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加AI动漫师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/aiComicSetting')
        }, { //加修图师设置
          path: 'retoucherSetting',
          name: "newWorkbench-retoucherSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加修图师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/retoucherSetting')
        },{ //AI挑图师设置
          path: 'aiChooseImg',
          name: "newWorkbench-aiChooseImg",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加挑图师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/aiChooseImg')
        }, ,{ //品控师设置
          path: 'qualityControlSetting',
          name: "newWorkbench-qualityControlSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加品控师'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/qualityControlSetting')
        },{ //流程说明
          path: 'processSetting',
          name: "newWorkbench-processSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-流程说明'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/processSetting')
        }, { // 审核员设置
          path: 'auditorSetting',
          name: "newWorkbench-auditorSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-加审核员'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/auditorSetting')
        }, { // 点赞气泡设置
          path: 'bubbleSetting',
          name: "newWorkbench-bubbleSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-点赞气泡'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/bubbleSetting')
        }, {// 活动推广设置
          path: 'promoteSetting',
          name: "newWorkbench-promoteSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-相册推广'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/promoteSetting')
        },  { // 在线投票设置
          path: 'votingSetting',
          name: "newWorkbench-votingSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-在线投票'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/votingSetting')
        }, {// 幸运抽奖设置
          path: 'luckDrawSetting',
          name: "newWorkbench-luckDrawSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-幸运抽奖'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/luckDrawSetting')
        }, { // 自定义相册菜单
          path: "customNav",
          name: "newWorkbench-customNav",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-相册菜单'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/customNav')
        },
        {//侧边栏设置
          path: "slidebarSetting",
          name: "newWorkbench-slidebarSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-侧边栏'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/slidebarSetting')
        },
        {//AI找图
          path: "aifindpicSetting",
          name: "newWorkbench-aifindpicSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-AI找图'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/aifindpicSetting')
        },
        {//大图信息设置
          path: "bigPictureSetting",
          name: "newWorkbench-bigPictureSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-大图信息'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/bigPictureSetting')
        },
        {//电脑分享设置
          path: "computerSharing",
          name: "newWorkbench-computerSharing",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-电脑分享设置'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/computerSharing')
        },
        {//营销获客设置
          path: "bookingShooting",
          name: "newWorkbench-bookingShooting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-营销获客'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/bookingShooting')
        },
        {//出图质量设置
          path: "figureQuality",
          name: "newWorkbench-figureQualitySetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-出图质量'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/figureQualitySetting')
        }, {
          path: 'bigScreenSetting',
          name: "newWorkbench-bigScreenSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-大屏播放'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/BigScreenSetting/index.vue')
        }, {
          path: 'customerInfoCollect',
          name: "newWorkbench-customerInfoCollect",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-登记表单'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/customerInfoCollect/index.vue')
        }, {
          path: 'skinStyleSetting',
          name: "newWorkbench-skinStyleSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-皮肤风格'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/skinStyleSetting/index.vue')
        }, {
          path: 'ownPlatformSetting',
          name: "newWorkbench-ownPlatformSetting",
          meta: {
            progress: true,
            antDCss: true,
            requiresAuth: true,
            describe: '相册设置-接入自有平台'
          },
          component: () => import(/* webpackChunkname: "newWorkbench" */ '../page/newWorkbench/setting/childPage/ownPlatformSetting/index.vue')
        }
      ]
      }, { // 打包页
        path: 'packageManage',
        name: "newWorkbench-packageManage",
        meta: {
          antDCss: true,
          requiresAuth: true,
          describe: '相册设置-付费打赏'
        },
        component: packageManage
      }, {//本地上传
        path: 'localUpload',
        redirect: '/newWorkbench/:id(\\d{10})/localUpload/imagesManagement/?:id(\\d{5})',
        component: () => import(/* webpackChunkName: "localUpload" */ '../page/newWorkbench/localUpload'),
        children:[{
          path: 'imagesManagement',
          name: "newWorkbench-imagesManagement",
          meta: {
            requiresAuth: true,
            antDCss: true,
            describe: '图片上传管理页面'
          },
          component: () => import(/* webpackChunkname: "newWorkbench-imagesManagement" */ '../page/newWorkbench/localUpload/childPage/imagesManagement')
        }, {
          path: 'videoManagement',
          name: "newWorkbench-videoManagement",
          meta: {
            requiresAuth: true,
            antDCss: true,
            describe: '视频上传管理页面'
          },
          component: () => import(/* webpackChunkname: "newWorkbench-videoManagement" */ '../page/newWorkbench/localUpload/childPage/videoManagement')
        }, {
          path: 'articleManagement',
          name: "newWorkbench-articleManagement",
          meta: {
            requiresAuth: true,
            antDCss: true,
            describe: '文章上传管理页面'
          },
          component: () => import(/* webpackChunkname: "newWorkbench-articleManagement" */ '../page/newWorkbench/localUpload/childPage/articleManagement')
        }]

      }]
    },

    // 新个人中心工作台
    {
      path: '/personalWorkbench',
      name: 'personalWorkbench',
      meta: {
        progress: true,
        requiresAuth: true,
        describe: '新个人中心工作台'
      },
      redirect: '/personalWorkbench/albumList',
      component: () => import(/* webpackChunkname: "personalWorkbench" */ '../page/personalWorkbench/index.vue'),
      children: [
        //新版相册列表
        {
          name: "albumList",
          path: 'albumList',
          component: () => import(/* webpackChunkname: "personalWorkbenchNewAlbumList" */ '../page/homePage/newAlbum-personWorkbenchUse.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-新版相册管理'
          }
        },
        {
          name: "oldAlbumList",
          path: 'oldAlbumList',
          component: () => import(/* webpackChunkname: "personalWorkbenchAlbumList" */ '../page/homePage/albuml-personworkbenchUse.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-旧版相册管理'
          }
        },
        {
          name: "specialAlbum",
          path: 'specialAlbum',
          component: () => import(/* webpackChunkname: "personalWorkbenchSpecialAlbum" */ '../page/homePage/specialAlbum-personworkbenchUse.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-专题管理'
          }
        },
        {
          name: "photoAlbumSet",
          path: 'photoAlbumSet',
          component: () => import(/* webpackChunkname: "personalWorkbenchAlbumSet" */ '../page/homePage/photoAlbumSet-personworkbenchUse.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-相册集管理'
          }
        },
        {
          name: "recycleAlbum",
          path: 'recycleAlbum',
          component: () => import(/* webpackChunkname: "personalWorkbenchRecycleAlbum" */ '../page/homePage/recycleAlbum-personworkbenchUse.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-回收站'
          }
        },
        {
          name: "orderHistory",
          path: 'orderHistory',
          component: () => import(/* webpackChunkname: "personalWorkbenchOrderHistory" */ '../page/personalCenter/childPage/orderHistory/orderHistory.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-购物订单'
          }
        },
        {
          name: "cloudStorage",
          path: 'cloudStorage',
          component: () => import(/* webpackChunkname: "personalWorkbenchCloudStorage" */ '../page/personalCenter/childPage/newCloudStorage/index.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-存储管理'
          }
        },
        {
          name: "freeArea",
          path: 'freeArea',
          component: () => import(/* webpackChunkname: "personalWorkbenchfreeArea" */ '../page/personalCenter/childPage/freeArea/index.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-免费专区'
          }
        },
        {
          name: "assets",
          path: 'property',
          component: () => import(/* webpackChunkname: "personalWorkbenchProperty" */ '../page/personalCenter/childPage/myAssets/index.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-券/资产'
          }
        },
        {
          name: "transferRecord",
          path: 'transferRecord',
          component: () => import(/* webpackChunkname: "personalWorkbenchProperty" */ '../page/personalCenter/childPage/transferRecord/index.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-转移记录'
          }
        },
        {
          name: "personalData",
          path: 'personalData',
          component: () => import(/* webpackChunkname: "personalWorkbenchPersonalData" */ '../page/personalCenter/childPage/personalData/personalData.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-个人资料'
          }
        },
        {
          name: "personalSetting",
          path: 'personalSetting',
          component: () => import(/* webpackChunkname: "personalWorkbenchPersonalData" */ '../page/personalCenter/childPage/personalSetting/index.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-个人资料与安全'
          }
        },
        {
          name: "realNamePage",
          path: 'realNamePage',
          component: () => import(/* webpackChunkname: "personalWorkbenchRealNamePage" */ '../page/personalCenter/childPage/realNamePage/realNamePage.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-实名认证'
          }
        },
        {
          name: "securityCenter",
          path: 'securityCenter',
          component: () => import(/* webpackChunkname: "personalWorkbenchSecurityCenter" */ '../page/personalCenter/childPage/securityCenter/securityCenter.vue'),
          meta: {
            progress: true,
            requiresAuth: true,
            antDCss: true,
            describe: '工作台-安全中心'
          }
        },
        {
          name: "flashTransmission",
          path: 'flashTransmission',
          component: () => import(/* webpackChunkname: "personalWorkbenchFlashTransmission" */ '../page/personalCenter/childPage/flashTransmission/flashTransmission.vue'),
          children: [{
            //购买闪传会员
            name: 'buyVip',
            path: "buyVip",
            component: newPersonalCenterBuyVip,
            meta: {
              progress: true,
              requiresAuth: true,
              antDCss: true,
              describe: '闪传商城-闪传会员'
            }
          },{
            //相册功能
            name: "advancedFeatures",
            path: "advancedFeatures",
            component: personalCenterAdvancedFeatures,
            meta: {
              progress: true,
              requiresAuth: true,
              antDCss: true,
              describe: '闪传商城-相册功能'
            }
          },{
            // 购买存储包
            name: "buyPackage",
            path: "buyPackage",
            component: personalStoragePackage,
            meta: {
              progress: true,
              requiresAuth: true,
              antDCss: true,
              describe: '闪传商城-购买存储包'
            }
          },
          // {
          //   // 购买闪传匣
          //   name: 'buyBox',
          //   path: "buyBox",
          //   component: personalCenterBuyBox,
          //   meta: {
          //     progress: true,
          //     requiresAuth: true
          //   }
          // },
          {
            // 购买服务
            name: 'buyService',
            path: "buyService",
            component: personalCenterBuyService,
            meta: {
              progress: true,
              requiresAuth: true
            }
          },{
            // 购买配件
            name: 'buyFittings',
            path: "buyFittings",
            component: personalCenterBuyFittings,
            meta: {
              progress: true,
              requiresAuth: true
            }
          },
          {
            // 购买720度全景相机
            name: 'buy720Camera',
            path: "buy720Camera",
            component: personalCenterBuy720Camera,
            meta: {
              progress: true,
              requiresAuth: true
            }
          }]
        }
      ]
    },
    //vip会员协议
    {
      path: '/vipduty',
      name: 'vipduty',
      meta: {
        requiresAuth: true
      },
      component: vipduty
    },
    // { //修图页面
    //   path: '/retouch',
    //   name: 'retouch',
    //   meta: {
    //     requiresAuth: true
    //   },
    //   component: retouch
    // },
    { //审核页面
      path: '/auditing/:id(\\d{10})',
      name: 'auditing',
      redirect: '/auditing/:id(\\d{10})/photoAudit',
      meta: {
        requiresAuth: true,
        toMoblie: true,
        describe: '审核页面',
      },
      component: auditing,
      children: [
        {
          name: "auditing-photoAudit",
          path: "photoAudit",
          component: photoAudit,
          meta: {
            requiresAuth: true,
            toMoblie: true,
            describe: '审核页面-照片审核',
            antDCss: true
          }
        }, {
          name: "auditing-videoAudit",
          path: "videoAudit",
          component: videoAudit,
          meta: {
            requiresAuth: true,
            toMoblie: true,
            describe: '审核页面-视频审核'
          }
        }
      ]
    },
    { //about页面
        path: '/about',
        name: 'about',
        children:[
            {
                name: "about-agree",
                meta: {
                    canNotLogin: true,
                },
                path: 'agree',
                component: aboutAgree,
                canNotLogin: true
            },
            {
                name: "about-feedback",
                meta: {
                    canNotLogin: true,
                },
                path: 'feedback',
                component: aboutFeedback,
                canNotLogin: true
            },
            {
                name: "about-news",
                meta: {
                    canNotLogin: true,
                },
                path: '/news/:type?/page:page?',
                alias: 'news',
                component: aboutNews,
                canNotLogin: true,
            },
            {
                name: "about-newsinfo",
                meta: {
                    canNotLogin: true,
                },
                path: '/news/:id',
                alias: 'news/:id',
                component: aboutNewsInfo,
                canNotLogin: true,
            },
            {
                name: "about-contactUs",
                meta: {
                    canNotLogin: true,
                },
                path: 'contactUs',
                component: aboutContactUs,
                canNotLogin: true
            },
            {
                name: "about-copyright",
                meta: {
                    canNotLogin: true,
                },
                path: 'copyright',
                component: aboutCopyRight,
                canNotLogin: true
            }
        ],
        redirect: '/about/agree',
        component: about
      },
      { //关于我们
        path: '/aboutus',
        name: 'aboutUs',
        meta: {
            canNotLogin: true,
            antDCss: true,
            describe: '关于我们'
        },
        component: aboutUs
      },
      { //协议页面
        path: '/userAgreement',
        name: 'userAgreement',
        meta: {
            canNotLogin: true
        },
        component: userAgreement
      },
      { //相册
        path: '/photoAlbum/:id/:pwd?',
        name: 'photoAlbum',
        meta: {
          canNotLogin: true
        },
        component: () => {
          const albumID = location.href.match(/\d{10}/)[0]
          location.href = `${ process.env.VUE_APP_ANTIBAN_DOMAIN }/album/${albumID}/`;
        }
      },
      {
        path: '/yearIntroduction',
        name: 'yearIntroduction',
        meta: {
            canNotLogin: true
        },
        component: yearIntroduction
      },
      {//PC摄影师合作页面
        path: '/cooperationPage',
        name: 'cooperationPage',
        meta: {
          canNotLogin: true,
          describe: '摄影师专区'
        },
        component: cooperationPage
      },
      {
        path: '/scoreDetail',
        name: 'scoreDetail',
        meta: {
          canNotLogin: true
        },
        component: scoreDetail
      },
      {
        path: '/openPlatform/',
        name: 'openPlatform',
        meta: {
          canNotLogin: true,
          antDCss: true
        },
        component: openPlatform
      },
      {
        path: '/memberBenefits/',
        name: 'memberBenefits',
        meta: {
          canNotLogin: true,
          antDCss: true
        },
        component: memberBenefits
      },
      {
        path: '/imgTextMessage/',
        name: 'imgTextMessage',
        component: messageIframe,
        meta: {
          canNotLogin: true,
          antDCss: true
        }
      },
      {
        path: '/industryTalent/',
        name: 'industryTalent',
        component: industryTalent,
        meta: {
          canNotLogin: true,
          antDCss: true
        }
      },
      // { path: '/about/agree', redirect: '/about/agree' },
      // { path: '/about/feedback', redirect: '/about/feedback' },
      // { path: '/about/contactUs', redirect: '/about/contactUs' },
      // { path: '/about/copyright', redirect: '/about/copyright' },
      // { path: '/about/us', redirect: '/aboutus' },
    { path: '/profile', redirect: '/albuml' },
    { path: '/u/home', redirect: '/login' },
    { path: '/matchs', redirect: '/cases' },
    { path: '/404', name: '404', component: notFound },
    // 处理之前的 峰站路径
    { path: '/(\\w{2}\/)?(\\w*)?', redirect: '/' },
    { //匹配不到跳转主页
      path: '*',
      meta: {
        canNotLogin: true
      },
      name: "notfound",
      component: notFound
    },
  ]
})

createPageProgress({
  router,
  filter(to, from) {
    return from.meta.progress
  }
})

router.beforeEach(async (to, from, next) => {
  if (to.query.token) {
    await action.SAVE_USERINFO({ commit: store.commit }, to.query.token)
    if(getCookie("cookieUserInfoV1")) {
      store.dispatch('SET_NOVICETASKLIST')
      store.dispatch('SET_STORAGEINFO')
    }
    next({
      name: to.name,
      params: to.params,
      query: {...to.query, token: undefined},
      hash: to.hash,
      replace: true
    })
    return
  }

  if( to.meta.describe ) {
    window.$track.add({ topic: 'pc页面访问', name: to.meta.describe })
  }
  if (to.meta.antDCss && !from.meta.antDCss) {
    window.$cdn.mount('antdCss', () => {
      // console.log('antdCss加载成功')
    }, (err) => {
        console.log('antdCss加载失败', err)
    })
  }
  // if (!to.meta.antDCss && from.meta.antDCss) {
  //   // 资源卸载
  //   window.$cdn.destroy('antdCss', () => {
  //     // console.log('antdCss卸载成功')
  //   }, (error) => {
  //     console.log('antdCss卸载失败', error)
  //   })
  // }

  if (['cases', 'videoCases', 'cooperationPage', 'specialTopic', 'aiIntroduce', 'aiSpin', 'aboutUs'].includes(to.name) && !to.hash) {
    window.scrollTo(0, 0)
  }
  // 如果加载到首页并且带query参数，就去掉
  if(['homePageIndex', 'vToHomePage'].includes(to.name) && to.fullPath.indexOf('?') > -1) {
    next({
      path: to.path
    })
  }else {
    next()
  }
})

router.afterEach((to, from) => {
  if (!to.meta.antDCss && from.meta.antDCss) {
      // 资源卸载
      window.$cdn.destroy('antdCss', () => {
        // console.log('antdCss卸载成功')
      }, (error) => {
        console.log('antdCss卸载失败', error)
      })
    }
  if (window.statisticsPageStayTime.enterTime) {
    switch (from.path) {
      case '/':
        window.$statisticsLeavePage('首页一页面停留时间')
        break;
      case '/cases':
        window.$statisticsLeavePage('精选案例一页面停留时间')
        break;
      case '/photoplatform':
        window.$statisticsLeavePage('开放平台一页面停留时间')
        break;
      case '/appdownload':
        window.$statisticsLeavePage('下载中心一页面停留时间')
        break;
      case '/aboutus':
        window.$statisticsLeavePage('关于我们一页面停留时间')
        break;
    }
  }
  aiTrackEvent(from, to)
  window.$statisticsEnterTime(to.path)
})

export default router
