/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 * imgBaseUrl: 图片所在域名地址
 */
let hostname = location.hostname,
    domain = location.origin,
    baseUrl,
    apiUrl,
    statisticsApi,
    cookieDomain,
    imgBaseUrl,
    wechatAppId, //微信appId
    wechatdebug = false, //微信debug
    retouchApi,
    cloudStorageAPI,
    vBaseUrl,
    retouchBaseUrl,
    albumRevisionUrl,
    gsUrl,
    rpsUrl,
    auditRpsUrl,
    uploadUrl,
    nowDomain,
    vconsole,
    whiteUrl,
    pcCaptchaAppId,
    pcUrl,
    appUrl,
    spuUrl,
    prUrl,
    globalVisitDomainMap,
    micrositeServerUrl
// 域
if (hostname.indexOf('alltuu') > -1) {
    domain = 'alltuu';
    nowDomain = 'alltuu';
    baseUrl = `https://am.${domain}.com`;
    apiUrl = baseUrl;
    cookieDomain = `.${domain}.com`;
    imgBaseUrl = 'https://sr.alltuu.com/vue-pc/images';
    wechatAppId = 'wx776c524e5ccccd55';
    wechatdebug = false;
    retouchApi = 'https://am.alltuu.com'
    statisticsApi = 'https://st.alltuu.com'
    cloudStorageAPI = 'https://cs.alltuu.com' // 喔图云空间
    vBaseUrl = 'https://alltuu.cc'
    retouchBaseUrl = `https://am.${domain}.com` // 修图
    rpsUrl = `wss://rps.${domain}.com` // 修图\审核后台
    auditRpsUrl = `https://rps.${nowDomain}.com`
    albumRevisionUrl = `https://nm.${domain}.com` //相册改版
    gsUrl = `https://gs.${domain}.com`
    uploadUrl = `https://ct.${domain}.com` //新的上传权限
    vconsole = false
    whiteUrl = 'https://sr.alltuu.com'
    pcCaptchaAppId = '196873233'
    pcUrl = `https://www.${domain}.com`
    appUrl = `https://app.${domain}.com`
    spuUrl = `https://spu.${domain}.com`
    prUrl = `https://pr.${domain}.com`
    micrositeServerUrl = `https://asc.${domain}.com`
} else if (hostname.indexOf('piufoto') > -1) {
    domain = 'piufoto';
    nowDomain = 'piufoto';
    baseUrl = `https://am.${domain}.com`;
    apiUrl = baseUrl;
    cookieDomain = `.${domain}.com`;
    imgBaseUrl = 'https://sr.alltuu.com/vue-pc/images';
    wechatAppId = 'wx67708636b32ad038';
    wechatdebug = false;
    retouchApi = 'https://am.piufoto.com'
    statisticsApi = 'https://ast.piufoto.com'
    cloudStorageAPI = 'https://acs.piufoto.com' // 喔图云空间
    vBaseUrl = `https://alltuu.cc`
    retouchBaseUrl = `https://am.piufoto.com` // 修图
    rpsUrl = `wss://ars.piufoto.com` // 修图\审核后台
    auditRpsUrl = `https://ars.piufoto.com`
    albumRevisionUrl = `https://anm.piufoto.com` //相册改版
    gsUrl = `https://gs.${domain}.com`
    uploadUrl = `https://act.piufoto.com` //新的上传权限
    vconsole = false
    whiteUrl = 'https://sr.alltuu.com'
    pcCaptchaAppId = '196873233'
    pcUrl = `https://www.piufoto.com`
    appUrl = `https://app.alltuu.com`
    spuUrl = `https://spu.alltuu.com`
    prUrl = `https://pr.alltuu.com`
    micrositeServerUrl = 'https://asc.alltuu.com'
} else if (hostname.indexOf('guituu') > -1) {
    domain = 'guituu';
    nowDomain = 'guituu';
    baseUrl = `https://am.${domain}.com`;
    apiUrl = baseUrl;
    cookieDomain = `.${domain}.com`;
    imgBaseUrl = 'https://sr.alltuu.com/vue-pc/guituu/images';
    wechatAppId = 'wx42585d05b255fe71'
    retouchApi = baseUrl
    statisticsApi = 'https://k.guituu.com'
    cloudStorageAPI = 'https://cs.guituu.com'
    vBaseUrl = `https://cc.guituu.com` 
    retouchBaseUrl = `https://am.${domain}.com`
    rpsUrl = `wss://rps.${domain}.com` // 修图\审核后台
    auditRpsUrl = `https://rps.${nowDomain}.com`
    albumRevisionUrl = `https://nm.${domain}.com` //相册改版
    gsUrl = `https://gs.${domain}.com`
    uploadUrl = `https://ct.${domain}.com`
    vconsole = false
    whiteUrl = 'https://sr.guituu.com'
    pcCaptchaAppId = '197239910'
    pcUrl = `https://www.${domain}.com`
    appUrl = `https://app.${domain}.com`
    spuUrl = `https://spu.${domain}.com`
    prUrl = `https://pr.${domain}.com`
    micrositeServerUrl = `https://asc.${domain}.com`
} else if (hostname.indexOf('showmephoto') > -1) {
    domain = 'showmephoto';
    nowDomain = 'showmephoto';
    baseUrl = `http://sa.${domain}.com`;
    apiUrl = baseUrl;
    cookieDomain = `.${domain}.com`;
    imgBaseUrl = 'http://sr.showmephoto.com/vue-pc/images';
    wechatAppId = 'wx42585d05b255fe71'
    retouchApi = baseUrl
    vconsole = false
    pcCaptchaAppId = '197239910'
} else {
    nowDomain = 'guituu';
    domain = 'guituu';
    // baseUrl = `https://m.alltuu.com`;
    baseUrl = `https://am.guituu.com`;
    // baseUrl = `http://192.168.10.74:8081`;
    apiUrl = baseUrl;
    cookieDomain = location.hostname;
    imgBaseUrl = 'https://sr.alltuu.com/vue-pc/guituu/images';
    wechatAppId = 'wx42585d05b255fe71'
    retouchApi = baseUrl
    statisticsApi = 'https://k.guituu.com'
    cloudStorageAPI = 'https://cs.guituu.com'
    vBaseUrl = `https://cc.guituu.com` 
    retouchBaseUrl = `https://am.${domain}.com`
    albumRevisionUrl = `https://nm.${domain}.com` //相册改版
    gsUrl = `https://gs.${domain}.com`
    rpsUrl = `wss://rps.${domain}.com` // 修图\审核后台
    auditRpsUrl = `https://rps.${nowDomain}.com`
    uploadUrl = `https://ct.${domain}.com`
    vconsole = false
    whiteUrl = 'https://sr.guituu.com'
    pcCaptchaAppId = '197239910'
    pcUrl = `https://www.${domain}.com`
    appUrl = `https://app.${domain}.com`
    spuUrl = `https://spu.${domain}.com`
    prUrl = `https://pr.${domain}.com`
    micrositeServerUrl = `https://asc.${domain}.com`
        //    domain = 'alltuu';
        //     nowDomain = 'alltuu';
        // 	baseUrl = `https://m.${domain}.com`;
        //     apiUrl = baseUrl;
        // 	cookieDomain = `.${domain}.com`;
        // 	imgBaseUrl = 'https://sr.alltuu.com/vue-pc/images';
        // 	wechatAppId = 'wx776c524e5ccccd55';
        // 	wechatdebug = false;
}

// 路由模式
let routerMode = 'history';

function IEVersion() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
    var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器  
    var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器  
    var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return 7;
        } else if (fIEVersion == 8) {
            return 8;
        } else if (fIEVersion == 9) {
            return 9;
        } else if (fIEVersion == 10) {
            return 10;
        } else {
            return 6; //IE版本<=7
        }
    } else if (isEdge) {
        return 'edge'; //edge
    } else if (isIE11) {
        return 11; //IE11  
    } else {
        return -1; //不是ie浏览器
    }
}





const $config = {
    domain,
    baseUrl,
    apiUrl,
    statisticsApi,
    cookieDomain,
    routerMode,
    imgBaseUrl,
    nowDomain,
    rpsUrl,
    wechatAppId,
    wechatdebug,
    IEVersion,
    retouchApi,
    cloudStorageAPI,
    vBaseUrl,
    retouchBaseUrl,
    albumRevisionUrl,
    gsUrl,
    auditRpsUrl,
    uploadUrl,
    vconsole,
    whiteUrl,
    pcCaptchaAppId,
    pcUrl,
    appUrl,
    spuUrl,
    globalVisitDomainMap,
    prUrl,
    micrositeServerUrl
};

window.$config = $config

export {
    domain,
    baseUrl,
    apiUrl,
    statisticsApi,
    cookieDomain,
    routerMode,
    imgBaseUrl,
    nowDomain,
    rpsUrl,
    wechatAppId,
    wechatdebug,
    IEVersion,
    retouchApi,
    cloudStorageAPI,
    vBaseUrl,
    retouchBaseUrl,
    albumRevisionUrl,
    gsUrl,
    auditRpsUrl,
    uploadUrl,
    vconsole,
    whiteUrl,
    pcCaptchaAppId,
    pcUrl,
    appUrl,
    spuUrl,
    globalVisitDomainMap,
    prUrl,
    micrositeServerUrl
}